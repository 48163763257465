<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { debounce } from "@/helpers/debounce";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  page: {
    title: "Log Aktifitas Pengguna",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
    VueJsonPretty,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "User", name: "-" },
      { width: "auto", label: "Role", name: "-" },
      { width: "auto", label: "Aktifitas", name: "-" },
      { width: "auto", label: "Waktu", name: "-" },
      { width: "100px", label: "Aksi", name: "-" },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Log Aktifitas Pengguna",
      items: [
        {
          text: "Log Aktifitas Pengguna",
          active: true,
        },
      ],
      tanggal_dari: null,
      tanggal_sampai: null,
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      // variable Page Table
      loadingTable: false,
      table_data: [],
      columns: columns,
      sortKey: "id", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        filter_status: "ENABLE", // untuk filtering data
        provinsi_id: null
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      // variabel referensi
      optionsProvinsi: [],

      // variabel page
      provinsi_selected: "",
      showModalDetail: false,
      dataDetail: null,
    };
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  mounted() {
    let self = this;
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(date) {
      return moment(date).format("DD MMMM YYYY HH:mm:ss");
    },
   
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "log-activity-user") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: self.sessionRoleId
        },
      };
      axios(config)
        .then(function (response) {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
                  // console.log(response_data_fix.list_data.data);
                  self.table_data = response_data_fix.list_data.data;
                  self.configPagination(response_data_fix.list_data);
                  self.currentTablePage = response_data_fix.list_data.current_page;
                  self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
        
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
   
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (url) {
      let self = this;
      self.getDataTable(url);
    },
    filterDatatable(){
      let self = this;
      self.tableData.provinsi_id = self.provinsi_selected?.id;
      self.getDataTable();
    },
    exportExcel(){
        let self = this;
        let config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "log-activity/export",
            params: self.tableData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
            responseType: 'blob',
        };
        axios(config)
            .then(function (response) {
                var blob = new Blob([response.data], { type: 'application/vnd-ms-excel' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Daftar Log Aktifitas Pengguna.xls'
                link.click()
            })
            .catch(function (error) {
                console.log(error);
            });  
    },
    ModalDetail(data){
      let self = this;
      self.showModalDetail = true;
      self.dataDetail = data;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Daftar Log Aktifitas Pengguna</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  &nbsp;
                  <button class="btn btn-sm btn-success m-1" @click="exportExcel"><i class="fas fa-file-excel"></i>   Export Data</button>
                  
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                      <label for="statusTable">Tanggal Dari</label>
                      <input type="date" v-model="tanggal_dari" id="" class="form-control">
                  </div>
                  <div class="form-group col-md-2">
                      <label for="statusTable">Tanggal Sampai</label>
                      <input type="date" v-model="tanggal_sampai" id="" class="form-control">
                  </div>
                  <div class="col-md-1">
                    <br />
                    <div class="btn btn-primary btn-sm mt-2" v-on:click="filterDatatable">
                      <i class="fa fa-filter"></i> Filter
                    </div>
                  </div>  
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row mb-2">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.per_page"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="searchDatatable()"
                    />
                  </div>
                </div>
                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                  <tbody>
                    <tr v-if = "loadingTable">
                      <td colspan="7" class="text-center">
                        <i class="fa fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if = "table_data.length == 0">
                      <td colspan="7" class="text-center">
                        Data tidak ditemukan
                      </td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        {{ ((parseInt(tableData.per_page) * parseInt(currentTablePage)) - parseInt(tableData.per_page)) + key_data + 1}}.
                      </td>
                      <!-- <td class="text-center">{{ key_data + 1 }}</td> -->
                      <td>{{ row_data.user_name }}</td>
                      <td class="text-center">{{ row_data.role_name }}</td>
                      <td class="text-center">{{ row_data.activity }}</td>
                      <td class="text-center">{{ fullDateTimeFormat(row_data.created_at) }}</td>
                      <td class="text-center">

                        <div class="btn-group" v-if="row_data.json_data != null">
                          <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#modalDetail"
                            @click="ModalDetail(row_data)"
                          >
                            <i class="fa fa-eye"> Detail</i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="showModalDetail" title="log" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="row">
                <div class="col-md-12" v-if="dataDetail != null">
                    <vue-json-pretty :data="dataDetail?.json_data" :view="1"></vue-json-pretty>
                  </div>
            </div>
        </div>
    </b-modal>
</template>
